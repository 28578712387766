<template lang="pug">
router-view
</template>

<script>
export default {
  name: 'MarketsAndMallsMallsMallId',
};
</script>

<style lang="stylus"></style>
